import React, { useEffect, useState } from "react";
import { SingleModule } from "@learnio-as/web-trial";
import "@learnio-as/web-trial/dist/index.css";
import NoSSR from "react-no-ssr";
import Header from "../components/atoms/header";
import MainSlider from "../components/molecules/MainSlider/MainSlider";
import Fullscreen from "../images/fullscreen.svg";
import ExitFullscreen from "../images/exitfullscreen.svg";
import { Helmet } from "react-helmet";
export default function Library() {
  const [loadAnimation, setLoadAnimation] = useState("");
  const [isFullscreen, setIsFullscreen] = useState(false);
  useEffect(() => {
    const handleFullscreenChange = () => {
      setIsFullscreen(!!document.fullscreenElement);
    };

    document.addEventListener("fullscreenchange", handleFullscreenChange);

    return () => {
      document.removeEventListener("fullscreenchange", handleFullscreenChange);
    };
  }, []);

  const toggleFullscreen = () => {
    if (!isFullscreen) {
      document.documentElement.requestFullscreen();
    } else {
      document.exitFullscreen();
    }
  };

  useEffect(() => {
    setLoadAnimation("fadeInOnLoad");
  }, []);

  const data = [
    {
      image:
        "https://res.cloudinary.com/dsg2ktuqk/image/upload/v1704217346/About_me_1_2_al9skg.png",
    },
    {
      image:
        "https://res.cloudinary.com/dsg2ktuqk/image/upload/v1704217347/Rectangle_15341_pqqkas.png",
    },
    {
      image:
        "https://res.cloudinary.com/dsg2ktuqk/image/upload/v1704217346/Rectangle_15349_urgmx8.png",
    },
    {
      image:
        "https://res.cloudinary.com/dsg2ktuqk/image/upload/v1704217346/Group_1321314325_jws4hw.png",
    },
    {
      image:
        "https://res.cloudinary.com/dsg2ktuqk/image/upload/v1704217346/Rectangle_15346_ssfuzt.png",
    },
  ];
  return (
    <>
      <Helmet>
        <title>Library</title>
        <link rel="canonical" href="https://masterwizr.com/library" />
      </Helmet>
      <button className="fullscreenBtn" onClick={toggleFullscreen}>
        {isFullscreen ? (
          <>
            <ExitFullscreen />
            <div className="fullscreenTooltip">
              <h3 className="tooltip">Exit Full Screen</h3>
            </div>
          </>
        ) : (
          <>
            <Fullscreen />
            <div className="fullscreenTooltip">
              <h3 className="tooltip">Go Full Screen</h3>
            </div>
          </>
        )}
      </button>
      <div className={`main-div ${loadAnimation}`}>
        <Header />
        <NoSSR>
          <SingleModule location={"/trial-steps"} module="library" />
        </NoSSR>
        <MainSlider
          slideWidth="85%"
          height="100%"
          slidesNumber={1}
          title="Product Snapshots"
          data={data}
        />
      </div>
    </>
  );
}
